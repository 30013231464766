import { useState } from 'react';
import './style.scss';

function Navigation() {
  const [isOpened, setIsOpened] = useState(false);

  const toggleNavigation = (e) => {
    e.preventDefault();
    setIsOpened(!isOpened);


  }

  return (
    <div className="navigation">
      <ul className="navigation__list">
        <li><a href="#">Features</a></li>
        <li><a href="#">Pricing</a></li>
        <li><a href="#">Contact</a></li>
        <li><a href="#">FAQs</a></li>

        <div className="navigation__btns">
          <a href="#" className="btn btn-isset">Log In</a>
        </div>
      </ul>
      

      <div className={`navigation__mobile ${isOpened ? 'open' : ''}`}>
        <button className="navigation__expand" onClick={toggleNavigation}>
          <span></span>
          <span></span>
          <span></span>
        </button>

        <ul className="navigation__list">
          <li><a href="#">Features</a></li>
          <li><a href="#">Pricing</a></li>
          <li><a href="#">Contact</a></li>
          <li><a href="#">FAQs</a></li>
          <div className="navigation__btns">
            <a href="#" className="btn btn-isset">Log In</a>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default Navigation;