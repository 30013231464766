import { useState } from 'react';
import './style.scss';


function AccordionItem({headerText, bodyText}) {
  const [isOpened, setIsOpened] = useState(false);

  const toggleBody = (e) => {
    setIsOpened(!isOpened);
  }
  return (
    <div className={`accordion__item ${isOpened ? 'open' : ''}`}>
      <div className="accordion__header" onClick={toggleBody}>
        <h5>{headerText}</h5>
      </div>
      <div className="accordion__body">
        <p>Tir nyrade lätthelg ett spekönt, i on i osk, lenat. Att jänäsk klimatlarm soren ock: tetrer. Konnomi prosm, kroryv på man i eude medan köporat. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,</p>
      </div>
    </div>
  )
}

export default AccordionItem;