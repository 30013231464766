
import './App.scss';
import Index from './pages/Landing/Landing';

function App() {
  return (
    <>
      <Index></Index>
    </>
  );
}

export default App;
