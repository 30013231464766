import AccordionItem from '../AccordionItem/AccordionItem';
import './style.scss'

function Accordion({ className }) {
  return (
    <div className={` accordion ${className}`}>
      <AccordionItem 
        headerText='What is the point of Placeholder Text?'
        bodyText='Tir nyrade lätthelg ett spekönt, i on i osk, lenat. Att jänäsk klimatlarm soren ock: tetrer. Konnomi prosm, kroryv på man i eude medan köporat. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,'
      ></AccordionItem>
      <AccordionItem 
        headerText='What is the point of Placeholder Text?'
        bodyText='Tir nyrade lätthelg ett spekönt, i on i osk, lenat. Att jänäsk klimatlarm soren ock: tetrer. Konnomi prosm, kroryv på man i eude medan köporat. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,'
      ></AccordionItem>
      <AccordionItem 
        headerText='What is the point of Placeholder Text?'
        bodyText='Tir nyrade lätthelg ett spekönt, i on i osk, lenat. Att jänäsk klimatlarm soren ock: tetrer. Konnomi prosm, kroryv på man i eude medan köporat. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,'
      ></AccordionItem>
      <AccordionItem 
        headerText='What is the point of Placeholder Text?'
        bodyText='Tir nyrade lätthelg ett spekönt, i on i osk, lenat. Att jänäsk klimatlarm soren ock: tetrer. Konnomi prosm, kroryv på man i eude medan köporat. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,'
      ></AccordionItem>
    </div>
  )
}

export default Accordion;