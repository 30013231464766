import './style.scss';

function Badge({text, children}) {
  return (
    <div className="badge">

      {children}
      {text}</div>
  );
}

export default Badge;