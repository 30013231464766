import './style.scss';

function Footer() {
  
  return (
    <footer>
        <div className="container">
          <div className="footer__top">
            <a href="" className="logo">Lessen AI</a>
            <ul className="footer__navigation">
              <li><a href="1">Features</a></li>
              <li><a href="2">Pricing</a></li>
              <li><a href="3">Contact</a></li>
              <li><a href="4">FAQs</a></li>
            </ul>
          </div>
          <div className="footer__basement">
            <div className="footer__copyrights">
              <p>© 2023. Lessen AI | All Rights Reserved.</p>
            </div>
            <ul className="footer__links">
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Privacy Policy</a></li>
            </ul>
          </div>
        </div>
      </footer>
  )
}

export default Footer;