import Navigation from '../Navigation/Navigation';
import './style.scss';

function Header() {

  return (
    <header className="header">
        <div className="container">
          <a href="#" className="logo">Lessen AI</a>
          <Navigation></Navigation>
        </div>
      </header>
  );
}

export default Header;